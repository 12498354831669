/*************************************************************/
/*  COPYRIGHT ©2014 BLOOMNATION, INC. | ALL RIGHTS RESERVED  */
/*************************************************************/
/*** BLOOM THEME ****/
/********************/

/**
 * Original Avenir implementation uses incorrect font weights.
 * The variables below serve as a reference to correct values based on
 * the desired font weight.
 */
:root {
	--bn--font-light: 50;
	--bn--font-normal: 100;
	--bn--font-medium: 200;
	--bn--font-demibold: 300;
	--bn--font-bold: 400;
}

p,
span {
	font-size: 14px;
	font-weight: inherit;
}
b, strong {
	font-weight: var(--bn--font-bold);
}
i {
	font-style: italic;
}
h1 {
	font-size: 14px;
	font-weight: var(--bn--font-demibold) !important;
	text-transform: capitalize;
}
h2 {
	font-size: 32px;
	font-weight: var(--bn--font-normal) !important;
	text-transform: capitalize;
}
h2.h2large {
	font-size: 42px;
}
h3 {
	font-size: 28px;
	font-weight: var(--bn--font-medium) !important;
}
h4 {
	font-size: 15px;
	font-weight: var(--bn--font-demibold) !important;
}
h5 {
	font-size: 14px;
	font-weight: var(--bn--font-medium) !important;
}
h6 {
	font-size: 12px;
	font-weight: var(--bn--font-bold) !important;
	text-transform: uppercase;
}
span, p {
	font-weight: var(--bn--font-medium) !important;
}
small {
	font-weight: var(--bn--font-medium) !important;
}
